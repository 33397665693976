@import 'assets/styles/_variables.scss';

body {
    font-family: $default-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.vertical-center {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 165px);
}

.default-text-color {
    color: $detail-color-default;
}

.text-color {
    color: $text-color;
}

.name-explorer {
    font-size: 14px;
    color: $text-color;
}

.w-70 {
    width: 70%;
}

.post-btn {
    font-family: $default-font-family !important;
    background: linear-gradient(0deg, #a9a9a9 0%, #dddddd 95.83%);
    box-shadow: -3px 3px 0px #636363, inset 2px 2px 4px rgba(248, 248, 248, 0.4);
    border-radius: 8px;
    border: none;
    font-size: 16px;
    height: 41px;
    color: #636363;
    min-width: 80px;
}

.cancel-btn {
    border: 0px;
    background: linear-gradient(180deg, rgba(205, 205, 205, 0.46) 0%, rgba(129, 129, 129, 0.46) 100%);
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    box-shadow: 2px 2px 8px 0px #00000040;
    color: #000;
    padding: 12px 28px;
    text-align: center;
    border-radius: 100px;
    white-space: nowrap;
}

.green-btn {
    font-family: $default-font-family;
    border: 0px;
    background: radial-gradient(202.8% 94.59% at 23.08% 7.65%, #2ee7ea 0%, #14a2a4 100%);
    box-shadow: 0px 6px 32px 0px #fff;
    border-image-source: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.822523) 34.76%,
        #ffffff 50%,
        rgba(255, 255, 255, 0.682874) 70.55%,
        rgba(255, 255, 255, 0) 99.5%
    );
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    border-radius: 100px;
    padding: 12px 28px;
    white-space: nowrap;
    color: #fff;
}

.green-btn-disabled {
    background: linear-gradient(0deg, #a9a9a9 0%, #dddddd 95.83%);
    box-shadow: -3px 3px 0px #636363, inset 2px 2px 4px rgba(248, 248, 248, 0.4);
    border-radius: 100px;
    font-size: 18px;
    color: #636363;
}

.red-btn {
    font-family: $default-font-family !important;
    background: radial-gradient(194.38% 88.4% at 23.08% 7.65%, #ff8181 0%, #c52323 100%);
    border-radius: 100px;
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
    border: none;
    padding: 0 28px;
    height: 42px;
}

.red-btn:hover {
    background: linear-gradient(0deg, #8d2529 0%, #c7464c 95.83%);
    box-shadow: -3px 3px 0px #673638, inset 2px 2px 4px rgba(255, 196, 198, 0.4);
    border-radius: 100px;
    font-size: 18px;
}

.red-btn-disabled {
    background: linear-gradient(0deg, #a9a9a9 0%, #dddddd 95.83%);
    box-shadow: -3px 3px 0px #636363, inset 2px 2px 4px rgba(248, 248, 248, 0.4);
    border-radius: 100px;
    font-size: 18px;
    color: #636363;
}

/* width */
::-webkit-scrollbar {
    display: none;
    width: 0px;
    height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #fff;
    border: 1px solid rgba(255, 255, 255, 0.1475);
    border-radius: 3.5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #fff;
}

.cursor-pointer {
    cursor: pointer;
}

.text-icon {
    font-size: 14px;
    color: $text-color;
    margin-left: 4px;
}

.text-error {
    font-size: 14px;
    color: red;
}

.mt-12 {
    margin-top: 12px;
}

.mb-12 {
    margin-bottom: 12px;
}

.ms-12 {
    margin-left: 12px;
}

.me-12 {
    margin-right: 12px;
}

.m-12 {
    margin: 12px;
}

.text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.mt-10 {
    margin-top: 10px;
}
.mb-24 {
    margin-bottom: 24px;
}

.text-sm {
    font-size: 14px;
}

.text-lg {
    font-size: 18px;
}

.whitespace-prewrap {
    white-space: pre-wrap;
}

.active-badge {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    background: $background-frame-color-default-2;
    padding: 8px 16px;
    border-radius: 100px;
    text-align: center;
    cursor: pointer;
    color: white;
    white-space: nowrap;
    border: none;
}

.inactive-badge {
    font-weight: 500;
    padding: 8px 16px;
    font-size: 14px;
    box-shadow: 2px 2px 8px 0px #00000040;
    line-height: 18px;
    background: #0000001f;
    border-radius: 100px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    border: none;
}

.ranking-medal {
    position: relative;
    .ranking-number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 13px;
        font-weight: bold;
    }
}

.ranking-order {
    width: 38.4px;
    height: 38.4px;
    border-radius: 100%;
    background: #ececec;
}
