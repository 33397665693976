@import '/src/assets/styles/variables';

.spot-item-wrapper {
    margin-bottom: 5px;
    background: $background-frame-color-default-2;
    box-shadow: inset -1px -1px 1px 0.5px rgba(0, 0, 0, 0.2), inset 1px 1px 1px rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 6px;
    .spot-item-border {
        padding: 8px;
        background: $background-frame-color-default;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.6),
            inset 0px 4px 4px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        .info-component {
            .status-btn {
                .spot-status {
                    height: 24px;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    font-size: 10px;
                }
                .active-status {
                    background: linear-gradient(0deg, #5b9c62 0%, #9ecd8b 95.83%);
                    box-shadow: -1px 2px 0px #46705e, inset 2px 2px 4px rgba(242, 254, 240, 0.4);
                    border-radius: 8px;
                    color: #fff;
                }
                .passive-status {
                    background: linear-gradient(0deg, #a3314b 0%, #d0627b 95.83%);
                    box-shadow: -1px 2px 0px #56202c, inset 2px 2px 4px rgba(242, 254, 240, 0.4);
                    border-radius: 8px;
                    color: #fff;
                }
            }
            .image-spot {
                border-radius: 4px;
                margin-right: 8px;
                cursor: pointer;
                object-fit: cover;
                width: 100%;
                aspect-ratio: 1 / 1;
            }
            .user-name {
                font-size: 12px;
            }
            .spot-name {
                font-size: 0.7rem;
                margin-left: 4px;
                margin-right: 6px;
            }
            .spot-item-detail {
                margin-top: 4px;
                display: flex;

                .spot-title {
                    font-size: 16px;
                    color: $detail-color-default;
                    max-height: 48px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    cursor: pointer;
                }
                .spot-comment {
                    font-size: 12px;
                    color: #414040;
                    max-height: 42px;
                    margin-bottom: 8px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    cursor: pointer;
                    -webkit-box-orient: vertical;
                    white-space: pre;
                }
                .spot-category-container {
                    gap: 4px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    .spot-category {
                        background: $border-frame;
                        border: 1px solid $detail-color-default;
                        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), -1px 1px 0px $detail-color-default,
                            inset 2px 2px 4px rgba(247, 240, 222, 0.4);
                        border-radius: 8px;
                        padding: 2px 10px;
                        font-size: 12px;
                        align-items: center;
                        text-align: center;
                        color: #fff;
                        height: 22px;
                        max-width: 180px;
                        .category-text {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 100%;
                        }
                    }
                }
            }
            .avatar-box{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                        line-clamp: 1; 
                -webkit-box-orient: vertical;
                .avatar-box-2{
                    font-size: 7px;
                }
            }
        }
        .first-info-item {
            margin-left: 8px;
            width: 100%;
            display: flex;
            .spot-info {
                display: flex;
                align-items: center;
                padding-right: 0;
                min-width: 138px;
            }
        }

        .icon-wrapper {
            margin-top: 7px;
            /*margin-left: 6px;*/
            .spot-clear-way {
                background: linear-gradient(0deg, #5b9c62 0%, #9ecd8b 95.83%);
                /*box-shadow: -3px 3px 0px #46705e, inset 2px 2px 4px rgba(242, 254, 240, 0.4);*/
                border-radius: 8px;
                color: #fff;
                border: none;
                padding: 2px 5px 2px 5px;
                font-size: 12px;
                align-items: center;
                text-align: center;
                height: 22px;
                max-width: 100%;
                width: fit-content;
                .spot-clear-way-text {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: clip;
                    max-width: 100%;
                }
            }
            .icon {
                display: flex;
                align-items: center;
                .spot-icon {
                    margin-left: 4px;
                    font-size: 12px;
                    color: #414040;
                }
            }
            .icon-min-width {
                min-width: 20px;
            }
        }
    }
}

.spot-opacity {
    opacity: 0.5;
    cursor: auto;
}

@media screen and (min-width: 768px) {
    .spot-item-wrapper {
        padding: 12px;

        .spot-item-detail {
            .spot-category-container {
                .spot-category {
                    max-width: 510px;
                }
            }
        }
    }
}
