@import '/src/assets/styles/variables';
.actiondetail-describe {
    padding: 8px;
    img {
        max-height: 11rem;
        max-width: 100%;
        object-fit: cover;
        border-radius: 8px;
    }
    .action-item {
        background: $border-frame;
        border: 1px solid $detail-color-default;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), -1px 1px 0px $detail-color-default,
            inset 2px 2px 4px rgba(247, 240, 222, 0.4);
        border-radius: 8px;
        font-size: 10px;
        align-items: center;
        text-align: center;
        color: #fff;
        padding: 4px 10px;
        margin-top: 8px;
    }
    .actiondetail-category {
        display: flex;
    }
    .describe {
        text-align: center;
        width: 100%;
        .title {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $detail-color-default;
            margin-bottom: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .desc {
            margin-bottom: 0px;
            font-size: 12px;
        }
    }
}
