@import '/src/assets/styles/variables';

.google-map-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    .location-input-container {
        position: absolute;
        top: 64px;
        left: 0;
        margin-left: 18px;
        margin-right: 16px;
        margin-top: 12px;
        width: 100%;
        .location-input {
            margin-right: 35px;
        }
    }
    .choose-map-btn {
        width: 100%;
    }
    .footer-map {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 12px 16px;
        background-color: $background-frame-color-default;
        border: 3px solid $detail-color-default;
        border-left: none;
        border-right: none;
        border-bottom: none;
        .footer-border {
            background: $background-frame-color-default-2;
            box-shadow: inset -1px -1px 1px 0.5px rgba(0, 0, 0, 0.2), inset 1px 1px 1px rgba(255, 255, 255, 0.8);
            border-radius: 8px;
            padding: 6px;
            margin-bottom: 12px;

            .footer-background {
                background: #fef5e7;
                box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.6),
                    inset 0px 4px 4px rgba(0, 0, 0, 0.05);
                border-radius: 8px;
                padding: 8px;
                .description-location {
                    display: flex;
                    align-items: center;
                    .description {
                        margin-left: 10px;
                        font-size: 14px;
                        color: #414040;
                    }
                }
            }
        }
    }
}
