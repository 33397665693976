@import '/src/assets/styles/variables';

body {
    // background: linear-gradient(0deg, $default-gradient-color 4.78%, $default-gradient-color-2 99.54%);
    background-image: url('/assets/images/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    margin-top: 0;
    margin-bottom: 0;
    min-width: 375px;
}

.body-container {
    min-height: 100vh;
}
